<template>
  <vs-popup
    classContent="popup-example"
    :title="lang.entities.add.popUpTitle[languageSelected]"
    :active.sync="activePopup"
  >
    <div>
      <vs-input
        class="mr-3 mt-2 w-full mb-base"
        v-model="newName"
        :label="lang.entities.add.name[languageSelected]"
        :disabled="saving"
      ></vs-input>
      <vs-divider />
      <input
        class="mb-2 mt-2"
        type="file"
        @change="inputFileChange"
        ref="inputFile"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <a
        class="float-right mb-2 mt-2"
        style="cursor: pointer;"
        @click="downloadTemplate"
        :disabled="saving"
      >
        {{ lang.entities.downloadTemplate[languageSelected] }}&nbsp;&nbsp;
      </a>
      <vs-divider />
      <a
        class="float-right mb-2"
        style="cursor: pointer;"
        @click="addValue"
        :disabled="saving"
        >{{ lang.entities.add.addValue[languageSelected] }}&nbsp;&nbsp;</a
      >
      <vs-table
        :data="newValues"
        sorted
        class="mt-2"
        :no-data-text="lang.general.noDataText[languageSelected]"
      >
        <template slot="thead">
          <vs-th sort-key="value">
            {{ lang.entities.add.valuesAndSynonyms[languageSelected] }}
          </vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            class="m-0 p-0"
          >
            <vs-td
              :data="data[indextr].synonyms"
              class="mt-4"
              style="padding-right: 33px"
            >
              <div class="vx-row mb-4">
                <vs-col class="w-2/3">
                  <vs-input
                    class="mt-0 mb-4 text-primary"
                    v-model="data[indextr].value"
                    :label="lang.entities.add.value[languageSelected]"
                    :disabled="saving"
                  ></vs-input>
                </vs-col>
                <vs-col class="w-1/3">
                  <feather-icon
                    icon="Trash2Icon"
                    class="float-right mt-6 pt-3 cursor-pointer text-danger"
                    svgClasses="h-5 w-5"
                    @click="removeValue(indextr)"
                    :disabled="saving"
                  />
                </vs-col>
              </div>

              <vs-divider class="mt-4" />
              <vs-input
                class="w-full mt-4 mb-4"
                v-model="newValuesInputs[indextr].synonym"
                :label="lang.entities.add.addSynonym[languageSelected]"
                @keypress.enter="addSynonym(indextr)"
                :disabled="saving"
              ></vs-input>
              <div class="vx-row mb-4">
                <vs-col>
                  <vs-chip
                    @click="removeSynonym(indextr, index)"
                    class="mr-2 mt-3 text-dark"
                    color="secundary"
                    v-for="(synonym, index) in data[indextr].synonyms"
                    :key="index"
                    closable
                    :disabled="saving"
                    >{{ synonym }}</vs-chip
                  >
                </vs-col>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-divider />
      <vs-button class="float-right" @click="save" :disabled="saving">
        {{ lang.entities.add.saveText[languageSelected] }}
      </vs-button>
      <vs-button
        class="float-right mr-4"
        color="danger"
        @click="cancel"
        :disabled="saving"
      >
        {{ lang.entities.add.cancelText[languageSelected] }}
      </vs-button>
    </div>
  </vs-popup>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import xlsx from './xlsx'
import BotMakerService from '../../../services/botMaker.service'

export default {
  name: 'AddEntity',
  props: {
    version: String,
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activePopup: false,
      saving: false,

      newName: '',
      newValues: [],
      newValuesInputs: [],

      chatMsgInput: null,

      selected: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['bot', 'entities'])
  },
  methods: {
    ...mapMutations('botMaker', ['UNSHIFT_ENTITY']),
    async inputFileChange(e) {
      const files = this.$refs.inputFile.files
      e.dataTransfer = { files }
      const result = await xlsx.handleDrop(e)
      if (result && result.tickets) {
        if (files[0].name) {
          this.newName = files[0].name.split('.')[0]
        }
        this.newValues = result.tickets
          .filter(element => element.value && element.synonyms)
          .map(element => ({
            value: element.value,
            synonyms: element.synonyms.split(',').map(item => item.trim())
          }))
        this.newValuesInputs = this.newValues.map(() => ({
          value: '',
          synonym: ''
        }))
      }
      console.log(files, result)
    },
    downloadTemplate() {
      window.open('/entity_template.xlsx', '_blank')
    },
    removeValue(indextr) {
      this.newValues.splice(indextr, 1)
      this.newValuesInputs.splice(indextr, 1)
    },
    removeSynonym(indextr, index) {
      if (!this.saving) {
        this.newValues[indextr].synonyms.splice(index, 1)
      }
    },
    addSynonym(index) {
      if (
        this.newValues[index].synonyms
          .map(element => element.toLowerCase())
          .indexOf(this.newValuesInputs[index].synonym.trim().toLowerCase()) >
        -1
      ) {
        // this.newValuesInputs[index].synonym = ''
        this.$vs.notify({
            text: this.lang.errors.errorDescription.synonyms[this.languageSelected],
            color: 'warning',
          })
        return
      }
      this.newValues[index].synonyms.push(
        this.newValuesInputs[index].synonym.trim()
      )
      this.newValuesInputs[index].synonym = ''
    },
    openPopup() {
      this.activePopup = true
      this.updateFields()
    },
    addValue() {
      this.newValues = [{ value: '', synonyms: [] }].concat(this.newValues)
      this.newValuesInputs = [{ value: '', synonym: '' }].concat(
        this.newValuesInputs
      )
    },
    updateFields() {
      this.$refs.inputFile.value = null
      this.newName = this.name
      this.newValues = [{ value: '', synonyms: [] }]
      this.newValuesInputs = [{ value: '', synonym: '' }]
    },
    cancel() {
      this.activePopup = false
    },
    async save() {
      function checkDuplicates(elements) {
        let values = []
        let synonyms = []
        const auxValues = []
        const auxSynonyms = []
        elements.forEach(element => {
          if (auxValues.indexOf(element.value.toLowerCase().trim()) === -1) {
            auxValues.push(element.value.toLowerCase().trim())
          } else {
            values.push(element.value)
          }
          element.synonyms.forEach(synonym => {
            if (auxSynonyms.indexOf(synonym.toLowerCase().trim()) === -1) {
              auxSynonyms.push(synonym.toLowerCase().trim())
            } else {
              synonyms.push(synonym)
            }
          })
        })
        return { values, synonyms }
      }
      if (!this.newName || this.newName.trim().length === 0) {
        return this.$vs.notify({
          title: this.lang.entities.validationErrors.invalidName.title[
            this.languageSelected
          ],
          text: this.lang.entities.validationErrors.invalidName.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      }
      if (this.newValues.length === 0) {
        return this.$vs.notify({
          title: this.lang.entities.validationErrors.notValues.title[
            this.languageSelected
          ],
          text: this.lang.entities.validationErrors.notValues.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      }
      const invalidValue = this.newValues.some(
        element => !element.value || element.value.trim().length === 0
      )
      if (invalidValue) {
        return this.$vs.notify({
          title: this.lang.entities.validationErrors.invalidValue.title[
            this.languageSelected
          ],
          text: this.lang.entities.validationErrors.invalidValue.text[
            this.languageSelected
          ],
          color: 'warning'
        })
      }
      const duplicates = checkDuplicates(this.newValues)
      if (duplicates.values.length > 0 || duplicates.synonyms.length > 0) {
        if (duplicates.values.length > 0) {
          this.$vs.notify({
            title: this.lang.entities.validationErrors.duplicateValues.title[
              this.languageSelected
            ],
            text:
              this.lang.entities.validationErrors.duplicateValues.text[
                this.languageSelected
              ] + `${JSON.stringify(duplicates.values)}`,
            color: 'warning'
          })
        }
        if (duplicates.synonyms.length > 0) {
          this.$vs.notify({
            title: this.lang.entities.validationErrors.duplicateSynonyms.title[
              this.languageSelected
            ],
            text:
              this.lang.entities.validationErrors.duplicateSynonyms.text[
                this.languageSelected
              ] + `${JSON.stringify(duplicates.synonyms)}`,
            color: 'warning'
          })
        }
        return
      }
      this.saving = true
      try {
        const newEntity = {
          version: this.version,
          name: this.newName,
          values: this.newValues
        }
        const result = await BotMakerService.createEntity(newEntity)
        this.$vs.notify({
          title: this.lang.entities.add.success.title[this.languageSelected],
          text: this.lang.entities.add.success.text[this.languageSelected],
          color: 'success'
        })
        this.UNSHIFT_ENTITY(result.data)
        this.cancel()
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 400 &&
          error.response.data &&
          error.response.data[0] &&
          error.response.data[0].message
        ) {
          this.$vs.notify({
            title: '400',
            text: error.response.data[0].message,
            color: 'danger'
          })
        } else {
          this.$vs.notify({
            title: this.lang.entities.add.error.title[this.languageSelected],
            text: this.lang.entities.add.error.text[this.languageSelected],
            color: 'warning'
          })
        }
      }
      this.saving = false
    }
  }
}
</script>
